import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-46d145ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "box"
};
export default {
  __name: 'PopTip',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    clickOverlay: {
      type: Function,
      default: () => {}
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_popup, {
        show: props.show,
        "close-on-click-overlay": false,
        round: "",
        class: "popup",
        onClickOverlay: props.clickOverlay
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "custom")])])]),
        _: 3
      }, 8, ["show", "onClickOverlay"])]);
    };
  }
};